import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private httpClient: HttpClient) {}

  getNotifications() {
    let header = new HttpHeaders().set(
      'Authorization',
      localStorage.getItem('token')
    );
    return this.httpClient.get<any>(
      `${environment.URL}notification-badge-data`,
      { headers: header }
    );
  }

  updateCredentials(
    patient_id: string,
    clientId: string,
    data: any
  ): Observable<any> {
    return this.httpClient
      .put<any>(
        environment.URL +
          'account/patient/' +
          patient_id +
          '/client/' +
          clientId +
          '/credentials',
        data
      )
      .pipe(map((response) => response || {}));
  }
}
