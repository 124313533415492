<div class="screen screen-7">
  <div class="order-otr">
    <a routerLink="/order-list">
      <svg
        class="icon"
        width="24"
        height="24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.47 17.78a.75.75 0 101.06-1.06l-3.97-3.97h8.69a.75.75 0 000-1.5H8.56l3.97-3.97a.75.75 0 00-1.06-1.06l-5.25 5.25c-.002 0-.003.003-.005.005a.747.747 0 00-.215.523v.004a.748.748 0 00.22.529m5.25 5.25l-5.25-5.25z"
          fill="#252F5A"
        />
      </svg>
    </a>
    <p class="heading">ORDER DETAILS</p>
  </div>
  <div class="icon-main">
    <div class="icon-otr">
      <div class="icon-inr">
        <span class="circle"></span>
        <svg
          class="check-icon"
          width="30"
          height="30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27.5 13.85V15a12.5 12.5 0 11-7.413-11.425"
            stroke="#fff"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M27.5 5L15 17.512l-3.75-3.75"
            stroke="#fff"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="text-otr">
        <p class="heading" *ngIf="this.orderDetails">
          {{ this.orderDetails.order_summary }}
        </p>
        <p class="desc" *ngIf="this.orderDetails && this.orderDetails.provider">
          {{ this.orderDetails.provider.description }}
        </p>
      </div>
    </div>
    <div class="text">
      <p class="heading">Physician Notes</p>
      <p class="desc" *ngIf="this.orderDetails">
        {{ this.orderDetails.physician_notes }}
      </p>
    </div>
  </div>
  <div class="schedule-main">
    <ul class="schedule-otr">
      <li class="schedule-inr">
        <p class="heading">Scheduled Date</p>
        <span class="desc" *ngIf="this.orderDetails">{{
          this.orderDetails.scheduled_date
        }}</span>
      </li>
      <li class="schedule-inr" *ngIf="this.time !== null">
        <p class="heading">Scheduled Time</p>
        <span class="desc" *ngIf="this.orderDetails">{{ this.time }}</span>
      </li>
      <li
        class="schedule-inr"
        *ngIf="
          this.orderDetails &&
          this.orderDetails.benefits.length &&
          this.orderDetails.benefits[0].est_out_of_pocket
        "
      >
        <p class="heading">Estimated out of pocket</p>
        <span
          class="desc"
          *ngIf="this.orderDetails && this.orderDetails.benefits.length"
          ><span
            class="desc"
            style="
              background-color: red;
              color: white;
              border-radius: 3px;
              padding: 0 1px 0 1px;
            "
            *ngIf="this.orderDetails.benefits[0].is_paid"
            >Paid</span
          >&nbsp;{{
            this.orderDetails.benefits[0].est_out_of_pocket
              | currency : "USD" : "symbol" : "1.2-2"
          }}</span
        >
      </li>
      <li class="schedule-inr">
        <p class="heading">Facility</p>
        <p
          class="desc mat-dialog-link"
          *ngIf="this.orderDetails"
          (click)="openDialog(this.orderDetails.location)"
        >
          {{ this.orderDetails.order_facility }}
        </p>
      </li>
      <li class="schedule-inr">
        <p class="heading">Phone</p>
        <!-- <a href="tel: {{this.facility_contact}}"> {{this.facility_contact}}</a> -->
        <a
          class="desc mat-dialog-link"
          *ngIf="this.facility_contact"
          href="tel: {{ this.facility_contact }}"
        >
          {{ this.facility_contact }}</a
        >
      </li>
    </ul>
  </div>
  <h4
    *ngIf="this.orderDetails && this.orderDetails.attachments.length > 0"
    style="text-align: center; font-family: Gilroy-Light; color: #252f5a"
  >
    Attachments
  </h4>
  <div
    class="schedule-main"
    *ngIf="this.orderDetails && this.orderDetails.attachments.length > 0"
  >
    <!-- <ul class="schedule-otr" *ngFor="let record of this.orderDetails.attachments; let j = index"> -->
    <ul
      class="schedule-otr"
      *ngFor="let record of this.orderDetails.attachments; let j = index"
    >
      <li class="schedule-inr-a schedule-inr">
        <p class="heading">
          <button
            type="button"
            class="btn-view-attachment"
            (click)="downloadFile(record.file_url)"
          >
            View
          </button>
          <!-- <div class="loader" [id]="j"></div> -->
        </p>
        <p class="desc">
          {{ record.file_name }}
        </p>
      </li>
      <hr class="divider-att" />
    </ul>
  </div>
  <div class="contact-btn" style="padding: 0 0 0 0 !important">
    <button
      routerLink="/conversation-list"
      class="btn-contact hand-cursor"
      type="button"
    >
      Contact My Provider
    </button>
  </div>
  <p class="status-heading" style="margin: 8px 0 8px 20px !important">STATUS</p>
  <div class="status-main">
    <ul class="status-otr" *ngIf="this.orderDetails">
      <li
        class="status-inr notification"
        *ngFor="let step of orderDetails.steps"
      >
        <!--COMMENTS Notification Badge-->
        <span
          class="badge hand-cursor"
          *ngIf="step.comments.length > 0"
          (click)="displayComments(step.step_id)"
          >{{ step.comments.length }}</span
        >

        <p class="heading" *ngIf="step_id !== step.step_id">
          {{ step.description }}
        </p>
        <div class="check" *ngIf="step_id !== step.step_id">
          <label>
            <span class="heading" style="font-size: 14px; margin-right: 4px">
              {{ step.status.description }}
            </span>
            <div
              class="check-inner"
              [ngStyle]="{ 'background-color': step.status.color }"
            >
              <i
                class="fa fa-hourglass-2"
                style="margin: 0.5em 0 0 0.6em; color: white"
                *ngIf="step.status.yellow"
              ></i>
              <input
                type="checkbox"
                [checked]="step.status.checked"
                class="input-check opacity-0 absolute"
                disabled
                *ngIf="step.status.white"
              />
              <i
                class="fa fa-ban"
                style="margin: 0.5em; margin-left: 0.6em; color: white"
                *ngIf="step.status.red"
              ></i>
              <svg
                class="fill-current"
                width="32"
                height="32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                *ngIf="step.status.white"
              >
                <circle cx="16" cy="16" r="16" fill="step.status.color" />
                <path
                  d="M23.417 15.772v.728a7.916 7.916 0 11-4.695-7.236"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M23.417 10.167L15.5 18.09l-2.375-2.375"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </label>
        </div>
        <div *ngIf="step_id === step.step_id">
          <span class="close hand-cursor" (click)="closeComments()"
            ><i class="fa fa-minus" aria-hidden="true"></i
          ></span>
          <div
            style="display: block"
            *ngFor="let comment of step.comments; let i = index"
          >
            <div style="display: block">
              <p class="heading">{{ comment.title }}</p>
              <p class="heading">{{ comment.comment }}</p>
            </div>
            <hr class="line" *ngIf="step.comments.length - 1 !== i" />
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div
    class="order-otr contact-btn"
    *ngIf="
      this.orderDetails &&
      this.orderDetails.benefits.length &&
      !this.orderDetails.benefits[0].is_paid
    "
  >
    <button
      class="btn-contact hand-cursor"
      type="button"
      (click)="navigatePayment()"
    >
      My Payments
    </button>
  </div>
  <p class="power-footer">Powered By VYTLsft</p>
</div>
