import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  constructor(private httpClient: HttpClient) {}
  public taskCurrentSlide: number;
  getAllTasks(pageNum?: number) {
    const recordIndex = JSON.parse(localStorage.getItem('multi_user_ids'));
    const httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('token'),
      patient_id: recordIndex[pageNum].patient_id,
      client_id: recordIndex[pageNum].client_id,
    });
    return this.httpClient.get<any>(`${environment.URL}tasks`, {
      headers: httpHeaders,
    });
  }

  getTasksDetails(taskId, patient_id) {
    const recordIndex = JSON.parse(localStorage.getItem('multi_user_ids'));
    const httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('token'),
      patient_id: recordIndex[patient_id].patient_id,
      client_id: recordIndex[patient_id].client_id,
    });
    return this.httpClient.get<any>(`${environment.URL}tasks/task/` + taskId, {
      headers: httpHeaders,
    });
  }

  async uploadImage(formData: FormData, taskId): Promise<any> {
    let header = new HttpHeaders().set(
      'Authorization',
      localStorage.getItem('token')
    );
    header.append('Content-Type', 'multipart/form-data');
    return await this.httpClient
      .post<any>(
        `${environment.URL}tasks/task/` + taskId + '/media',
        formData,
        { headers: header }
      )
      .toPromise();
  }

  submitTask(taskId, model) {
    let header = new HttpHeaders().set(
      'Authorization',
      localStorage.getItem('token')
    );
    return this.httpClient.post<any>(
      `${environment.URL}tasks/task/` + taskId + '/submit',
      model,
      { headers: header }
    );
  }
}
