import { Component, OnInit, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TasksService } from '../services/tasks.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-tasks',
  templateUrl: './my-tasks.component.html',
  styleUrls: ['./my-tasks.component.css'],
})
export class MyTasksComponent implements OnInit {
  @ViewChild('owlCar', { static: false }) owlCar: any;
  response: any;
  currentSlide: number;
  clientName: string;
  provider: string;
  clientResponse: any;
  dynamicSlides: any;
  arrayOfUsers: any;
  recordIndex: any;
  constructor(
    private taskSerice: TasksService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.currentSlide = 0;
    this.dynamicSlides = {};
    this.arrayOfUsers = [];
    this.getUsers();
    if (localStorage.getItem('token')) {
      if (this.taskSerice.taskCurrentSlide === 0 || this.taskSerice.taskCurrentSlide === undefined) {
        this.taskSerice.taskCurrentSlide = 0;
        this.getAllTasks(this.taskSerice.taskCurrentSlide);
      } 
    }
  }
  ngAfterViewInit() {
    if (this.owlCar) {
      this.owlCar.to(this.taskSerice.taskCurrentSlide.toString());
    }
  }
  ngOnInit(): void { }
  getUsers() {
    this.recordIndex = JSON.parse(localStorage.getItem('multi_user_ids'));
    if (this.recordIndex !== null && this.recordIndex.length > 0) {
      for (var i = 0; i < this.recordIndex.length; i++) {
        this.arrayOfUsers[i] = '';
      }
    }
  }

  getData($event) {
    this.currentSlide = $event.startPosition;
    this.taskSerice.taskCurrentSlide = $event.startPosition;
    if (this.arrayOfUsers[$event.startPosition] === '') {
      this.getAllTasks($event.startPosition);
    } else {
      return;
    }
  }

  getAllTasks(user: number) {
    this.spinner.show();
    this.taskSerice.getAllTasks(user).subscribe(
      (response) => {
        this.response = response.data;
        this.dynamicSlides = {
          client: response.data.client,
          tasks: response.data.tasks,
        };
        if (this.arrayOfUsers[user] === '') {
          this.arrayOfUsers[user] = this.dynamicSlides;
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error.error.message);
        console.log(error);
      }
    );
  }

  customOptions: OwlOptions = {
    loop: false,
    dots: true,
    navSpeed: 600,
    nav: false,
    autoHeight: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      760: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  navigateTaskDetails(taskId) {
    this.router.navigate(['task-detail/' + taskId + '/' + this.currentSlide]);
  }
  goback() {
    this.taskSerice.taskCurrentSlide = 0;
  }
}
