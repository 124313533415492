import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProviderService } from '../services/provider.service';
import { TalkService } from '../services/talk.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-conversation-list',
  templateUrl: './conversation-list.component.html',
  styleUrls: ['./conversation-list.component.css'],
})
export class ConversationListComponent implements OnInit {
  providerList: any;
  constructor(
    private providerService: ProviderService,
    private toastr: ToastrService,
    private location: Location,
    private router: Router
  ) {
    this.getAllClients();
  }

  ngOnInit(): void {}

  getAllClients() {
    this.providerService.getAllClients().subscribe(
      (response) => {
        this.providerList = response.data;
      },
      (error) => {
        this.toastr.error(error.error.message);
        console.log(error);
      }
    );
  }
  back(): void {
    this.location.back();
  }

  navigateChatBox(practiceId, practiceName) {
    this.router.navigate([
      '/contact-office/' + practiceId + '/' + practiceName,
    ]);
  }
}
