<div class="screen screen-tsk screen-8">
    <div class="order-otr">
        <a href="/dashboard">
            <svg class="icon" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.47 17.78a.75.75 0 101.06-1.06l-3.97-3.97h8.69a.75.75 0 000-1.5H8.56l3.97-3.97a.75.75 0 00-1.06-1.06l-5.25 5.25c-.002 0-.003.003-.005.005a.747.747 0 00-.215.523v.004a.748.748 0 00.22.529m5.25 5.25l-5.25-5.25z"
                        fill="#252F5A"
                    />
                </svg>
        </a>
        <div class="logo">
            <img class="logo-img" src="assets/img/pay.png" alt="logo">
        </div>
    </div>
    <div class="main-task" style="height: 80%;">
        <p class="heading">Oops!</p>
        <div class="sad-icon">
            <i class="fa fa-frown-o" aria-hidden="true"></i>
        </div>
        <p class="heading-2">404 - PAGE NOT FOUND</p>
        <p class="desc">Sorry, we couldn't find this page.</p>
    </div>

</div>
