<div class="screen screen-4">
  <div class="logo">
    <div *ngIf="!brandlogo" class="image-placeholder"></div>
    <img
      *ngIf="this.brandlogo"
      class="logo-img"
      src="{{ this.brandlogo }}"
      alt="logo"
    />
  </div>

  <!-- Login using PIN//start -->
  <div *ngIf="loginWithPIN" [@fadeIn]>
    <div style="text-align: center; margin: 26px 0 21px 0">
      <span
        class="dot"
        [style.background-color]="
          this.inputModel.pin_code && this.inputModel.pin_code.length >= 1
            ? '#bbb'
            : null
        "
      ></span>
      <span
        class="dot"
        [style.background-color]="
          this.inputModel.pin_code && this.inputModel.pin_code.length >= 2
            ? '#bbb'
            : null
        "
      ></span>
      <span
        class="dot"
        [style.background-color]="
          this.inputModel.pin_code && this.inputModel.pin_code.length >= 3
            ? '#bbb'
            : null
        "
      ></span>
      <span
        class="dot"
        [style.background-color]="
          this.inputModel.pin_code && this.inputModel.pin_code.length === 4
            ? '#bbb'
            : null
        "
      ></span>
    </div>
    <div class="num-otr">
      <button class="button num-inr num-1 hand-cursor" (click)="numberPress(1)">
        <h1 class="num">1</h1>
      </button>
      <button class="button num-inr num-2 hand-cursor" (click)="numberPress(2)">
        <h1 class="num">2</h1>
      </button>
      <button class="button num-inr num-3 hand-cursor" (click)="numberPress(3)">
        <h1 class="num">3</h1>
      </button>
      <button class="button num-inr num-4 hand-cursor" (click)="numberPress(4)">
        <h1 class="num">4</h1>
      </button>
      <button class="button num-inr num-5 hand-cursor" (click)="numberPress(5)">
        <h1 class="num">5</h1>
      </button>
      <button class="button num-inr num-6 hand-cursor" (click)="numberPress(6)">
        <h1 class="num">6</h1>
      </button>
      <button class="button num-inr num-7 hand-cursor" (click)="numberPress(7)">
        <h1 class="num">7</h1>
      </button>
      <button class="button num-inr num-8 hand-cursor" (click)="numberPress(8)">
        <h1 class="num">8</h1>
      </button>
      <button class="button num-inr num-9 hand-cursor" (click)="numberPress(9)">
        <h1 class="num">9</h1>
      </button>
      <button class="button num-inr num-10 hand-cursor" (click)="pinDelete()">
        <h1 class="num"><i class="fa fa-times" aria-hidden="true"></i></h1>
      </button>
      <button
        class="button num-inr num-10 hand-cursor"
        (click)="numberPress(0)"
      >
        <h1 class="num">0</h1>
      </button>
      <button class="button num-inr num-backspace hand-cursor">
        <h1 class="num">
          <img
            src="assets/icons/backspace.png"
            style="filter: brightness(0) invert(1)"
            (click)="pinBackspace()"
            alt="hidden"
          />
        </h1>
      </button>
    </div>
    <div class="container-end">
      <h1 class="reset-pin" (click)="resetPrompt()">Forgot PIN?</h1>
    </div>
  </div>
  <!-- Login using PIN//end -->

  <!-- Login using Credentials//start -->
  <div *ngIf="loginWithCredentials" [@fadeIn]>
    <div class="login-field-button">
      <div class="input-otr-password">
        <form [formGroup]="signinForm" (submit)="login(true)">
          <div class="form-group">
            <label class="input-text-1">Username:</label>
            <div
              *ngIf="f.username.touched || f.username.dirty"
              class="alert alert-danger"
            >
              <p *ngIf="f.username.errors?.minlength" class="error-text">
                <i class="fa fa-times mr-1" aria-hidden="true"></i>Username must
                be atleast 6 characters long.
              </p>
              <p *ngIf="f.username.errors?.maxlength" class="error-text">
                <i class="fa fa-times mr-1" aria-hidden="true"></i>Username can
                only be of 20 characters max.
              </p>
              <p *ngIf="f.username.errors?.required" class="error-text">
                <i class="fa fa-times mr-1" aria-hidden="true"></i>Username is
                required to log in.
              </p>
            </div>

            <input type="text" class="input" formControlName="username" />
          </div>
          <div class="container-end" *ngIf="loginWithCredentials">
            <h1 class="reset-pass" (click)="forgotUserNamePrompt()">
              Forgot Username?
            </h1>
          </div>
          <div class="form-group" style="margin-top: 4%">
            <label class="input-text-1">Password:</label>
            <div *ngIf="f.password.touched || f.password.dirty">
              <p *ngIf="f.password.errors?.required" class="error-text">
                <i class="fa fa-times mr-1" aria-hidden="true"></i>Password is
                required to login.
              </p>
              <p *ngIf="f.password.errors?.minlength" class="error-text">
                <i class="fa fa-times mr-1" aria-hidden="true"></i>Password must
                be atleast 8 characters long.
              </p>
            </div>
            <input
              class="input"
              [type]="!showPass ? 'password' : 'text'"
              formControlName="password"
            />
            <img
              [src]="
                !showPass
                  ? 'assets/icons/hidden.png'
                  : 'assets/icons/disclosed.png'
              "
              (click)="togglePassword()"
              class="img-inr eye-icon hand-cursor"
              height="25"
              alt="hidden"
            />
          </div>
          <div class="container-end" *ngIf="loginWithCredentials">
            <h1 class="reset-pass" (click)="resetPrompt(true)">
              Forgot Password?
            </h1>
          </div>
          <div class="container-center">
            <button
              class="btn login-button"
              type="submit"
              [disabled]="signinForm.invalid"
            >
              LOGIN
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Login using Credentials//end -->

  <!-- VERIFY MFA SCREEN//start-->
  <div *ngIf="verifyMFAScreen" [@fadeIn]>
    <div class="login-field-button">
      <h1 class="mfa-heading">TWO-FACTOR AUTHENTICATION</h1>
      <div class="container-center">
        <h1 class="or">
          Please enter the Security code sent to your number ending with
          <u>{{ cell_phone_end }}</u>
        </h1>
      </div>
      <div class="input-otr-password" style="margin-top: 3%">
        <form [formGroup]="verifyCode">
          <div class="form-group">
            <div
              *ngIf="f2.MFACode?.touched || f.MFACode?.dirty"
              class="alert alert-danger"
            >
              <p
                *ngIf="f2.MFACode.errors?.minlength"
                class="error-text error-text-alt"
              >
                <i class="fa fa-times mr-1" aria-hidden="true"></i>Security code
                must be atleast 6 characters long.
              </p>
              <p
                *ngIf="f2.MFACode.errors?.pattern"
                class="error-text error-text-alt"
              >
                <i class="fa fa-times mr-1" aria-hidden="true"></i>Only numbers
                are allowed in the Security code.
              </p>
              <p
                *ngIf="f2.MFACode.errors?.required"
                class="error-text error-text-alt"
              >
                <i class="fa fa-times mr-1" aria-hidden="true"></i>Security code
                is required to reset.
              </p>
            </div>

            <input
              type="text"
              class="input"
              style="text-align: center"
              maxlength="6"
              formControlName="MFACode"
            />
            <div class="container-end">
              <h1 class="reset-pass" (click)="resendMFACode()">Resend Code</h1>
            </div>
          </div>
          <div class="container-center">
            <button
              class="login-button"
              type="submit"
              [disabled]="verifyCode?.invalid"
              (click)="verifyMFACode()"
            >
              RESET
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- VERIFY MFA SCREEN//end-->

  <!-- RESET CREDENTIALS SCREEN//start-->
  <div *ngIf="changePINPass" [@fadeIn]>
    <div class="login-field-button">
      <div class="container-center" style="margin: 10% 0 10% 0">
        <h1 class="or">
          Please enter your new {{ changePass ? "Password" : "PIN" }}
        </h1>
      </div>
      <div class="input-otr-password">
        <form [formGroup]="resetCredentials" (submit)="updateCredentials()">
          <!-- PASSWORD CASE//start-->
          <div *ngIf="changePass" [@fadeIn]>
            <div class="form-group group-position">
              <label class="input-text-1">Password</label>

              <input
                class="input"
                [type]="!showPass ? 'password' : 'text'"
                formControlName="password"
                [(ngModel)]="inputModel.password"
              />
              <img
                [src]="
                  !showPass
                    ? 'assets/icons/hidden.png'
                    : 'assets/icons/disclosed.png'
                "
                (click)="togglePassword()"
                class="img-inr eye-icon hand-cursor"
                height="25"
                alt="hidden"
              />
            </div>
            <div class="form-group group-position">
              <label class="input-text-1" style="margin-top: 2%"
                >Confirm Password</label
              >
              <input
                class="input"
                [type]="!showConfirmPass ? 'password' : 'text'"
                formControlName="confirm_password"
                [(ngModel)]="inputModel.confirm_password"
              />
              <img
                [src]="
                  !showConfirmPass
                    ? 'assets/icons/hidden.png'
                    : 'assets/icons/disclosed.png'
                "
                (click)="toggleConfirmPassword()"
                class="img-inr eye-icon hand-cursor"
                height="25"
                alt="hidden"
              />
            </div>
            <div
              *ngIf="
                (f3.password.touched || f3.password.dirty) &&
                (f3.confirm_password.touched || f3.confirm_password.dirty)
              "
              class="alert alert-danger"
            >
              <p *ngIf="resetCredentials.errors?.mismatch" class="error-text">
                <i class="fa fa-times mr-1" aria-hidden="true"></i>Passwords do
                not match.
              </p>
              <p
                *ngIf="!resetCredentials.errors?.mismatch"
                class="error-text"
                style="color: green"
              >
                <i class="fa fa-check mr-1" aria-hidden="true"></i>Passwords
                matched
              </p>
            </div>
            <div *ngIf="f3.password.touched || f3.password.dirty">
              <p *ngIf="f3.password.errors?.minlength" class="error-text">
                <i class="fa fa-times mr-1" aria-hidden="true"></i>Password must
                be atleast 8 characters long.
              </p>
            </div>
          </div>
          <!-- PASSWORD CASE//end-->

          <!-- PIN CASE//start -->
          <div *ngIf="changePIN" [@fadeIn]>
            <div class="form-group group-position">
              <label class="input-text-1">PIN</label>

              <input
                class="input"
                maxlength="4"
                [type]="!showPass ? 'password' : 'text'"
                formControlName="pin_code"
                [(ngModel)]="inputModel.pin_code"
              />
              <img
                [src]="
                  !showPass
                    ? 'assets/icons/hidden.png'
                    : 'assets/icons/disclosed.png'
                "
                (click)="togglePassword()"
                class="img-inr eye-icon hand-cursor"
                height="25"
                alt="hidden"
              />
            </div>
            <div class="form-group group-position">
              <label class="input-text-1" style="margin-top: 2%"
                >Confirm PIN</label
              >
              <input
                class="input"
                maxlength="4"
                [type]="!showConfirmPass ? 'password' : 'text'"
                formControlName="confirm_pin_code"
                [(ngModel)]="inputModel.cnfrm_pin_code"
              />
              <img
                [src]="
                  !showConfirmPass
                    ? 'assets/icons/hidden.png'
                    : 'assets/icons/disclosed.png'
                "
                (click)="toggleConfirmPassword()"
                class="img-inr eye-icon hand-cursor"
                height="25"
                alt="hidden"
              />
            </div>
            <div
              *ngIf="
                (f3.pin_code.touched || f3.pin_code.dirty) &&
                (f3.confirm_pin_code.touched || f3.confirm_pin_code.dirty)
              "
              class="alert alert-danger"
            >
              <p *ngIf="resetCredentials.errors?.mismatch" class="error-text">
                <i class="fa fa-times mr-1" aria-hidden="true"></i>PINs do not
                match.
              </p>
              <p
                *ngIf="!resetCredentials.errors?.mismatch"
                class="error-text"
                style="color: green"
              >
                <i class="fa fa-check mr-1" aria-hidden="true"></i>PINs matched
              </p>
            </div>
            <div *ngIf="f3.pin_code.touched || f3.pin_code.dirty">
              <p *ngIf="f3.pin_code.errors?.minlength" class="error-text">
                <i class="fa fa-times mr-1" aria-hidden="true"></i>PIN code must
                be atleast 4 characters long.
              </p>
              <p *ngIf="f3.pin_code.errors?.pattern" class="error-text">
                <i class="fa fa-times mr-1" aria-hidden="true"></i>PIN code must
                only contain numbers.
              </p>
            </div>
          </div>
          <!-- PIN CASE//end -->

          <div class="container-center" style="margin-top: 4%">
            <button
              class="login-button"
              type="submit"
              [disabled]="resetCredentials?.invalid"
              (click)="(updateCredentials)"
            >
              UPDATE
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- RESET CREDENTIALS SCREEN//end-->

  <!-- MAIN LOGIN MENU -->
  <div *ngIf="!verifyMFAScreen && !changePINPass" [@fadeIn]>
    <div class="container-center" *ngIf="loginWithCredentials && !disablePIN">
      <h1 class="or">OR</h1>
    </div>

    <div class="container-center" *ngIf="!loginWithPIN && !disablePIN">
      <button
        class="switch-button"
        [ngClass]="!loginWithPIN && !loginWithCredentials ? 'margin-login' : ''"
        (click)="switchLoginToPIN()"
      >
        <span *ngIf="apiExecuted">LOGIN USING PIN</span>
        <span *ngIf="!apiExecuted">CREATE ACCOUNT</span>
        <i class="fa fa-key" style="margin-left: 3%" aria-hidden="true"></i>
      </button>
    </div>

    <div
      class="container-center margin-login"
      *ngIf="
        !loginWithCredentials &&
        disablePIN &&
        inputModel.client_id &&
        inputModel.patient_id
      "
    >
      <button class="switch-button" (click)="router.navigate(['sign-up'])">
        <span>CREATE ACCOUNT</span>
        <i class="fa fa-key" style="margin-left: 3%" aria-hidden="true"></i>
      </button>
    </div>

    <div class="container-center" *ngIf="!loginWithCredentials">
      <h1 class="or">OR</h1>
    </div>

    <div class="container-center" *ngIf="!loginWithCredentials">
      <button class="switch-button" (click)="switchLoginToCredentials()">
        <span>LOGIN USING CREDENTIALS</span>
        <i
          class="fa fa-envelope"
          style="margin-left: 3%"
          aria-hidden="true"
        ></i>
      </button>
    </div>
  </div>

  <!-- RESET PIN (DELETE USER ACCOUNT AND CREATE NEW ONE) -->
  <div *ngIf="resetPin && !verifyMFAScreen" class="container-center">
    <button class="btn reset-button" type="submit" (click)="resetPinApi()">
      Reset Account
    </button>
  </div>

  <!-- Footer -->
  <p class="power-footer">Powered By VYTLsft</p>
</div>
