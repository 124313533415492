export class signupModel {
  client_id: string;
  patient_id: string;
  practice_id: string;
  last_name: string;
  date_of_birth: string;
  username: string;
  password: string;
  confirm_password: string;
  // pin_code: string;
  // cnfrm_pin_code: string;
  mfa_code: string;
  cell_phone?: string;
}
export class verificationModel {
  client_id: string;
  cell_phone: string;
  patient_id: string;
  last_name: string;
  date_of_birth: string;
}

export class loginModel {
  client_id: string;
  patient_id: string;
  practice_id: string;
  last_name: string;
  date_of_birth: string;
  username: string;
  password: string;
  confirm_password: string;
  pin_code: string;
  cnfrm_pin_code: string;
  mfa_code: string;
}
