import { OrderService } from './services/order.service';
import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil } from 'rxjs/operators';
import { LoginService } from './services/login.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  @HostBinding('style.--primary-color') Primary_color = localStorage.getItem(
    'primary_color'
  )
    ? localStorage.getItem('primary_color')
    : '#073786';
  @HostBinding('style.--secondary-color') Secondry_color = localStorage.getItem(
    'secondry_color'
  )
    ? localStorage.getItem('secondry_color')
    : '#0D66FA';
  @HostBinding('style.--primary_color_light') Primary_color_light =
    localStorage.getItem('primary_color_light' || '#4A6186');
  private unsubscribeSource: Subject<any>;
  primary_color_light: string;

  constructor(
    private route: ActivatedRoute,
    private orderService: OrderService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.unsubscribeSource = new Subject<any>();
    this.initSubscription();
  }

  initSubscription() {
    this.subscribeToQueryParams();
  }

  ngOnDestroy() {
    this.unsubscribeSource.next();
    this.unsubscribeSource.complete();
  }

  getLightColor(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
          result[3],
          16
        )},0.6)`
      : null;
  }

  private subscribeToQueryParams() {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribeSource))
      .subscribe((params) => {
        let arr = window.location.href.split('/');
        if (
          (/^(?=.{8}$)[A-Za-z0-9_-]+$/.test(arr[arr.length - 1]) &&
            arr[arr.length - 1].length > 6 &&
            arr[arr.length - 1].length < 9 &&
            !arr[arr.length - 1].includes('success') &&
            !arr[arr.length - 1].includes('fail')) ||
          (Object.keys(params).length > 0 &&
            !params.success &&
            !params.orderId &&
            !params.benefitId)
        ) {
          console.log('local storage cleared');
          localStorage.clear();
        }

        if (params.client_id) {
          localStorage.setItem('client_id', params.client_id);
        }
        if (params.patient_id) {
          localStorage.setItem('patient_id', params.patient_id);
        }
        if (params.practice_id) {
          localStorage.setItem('practice_id', params.practice_id);
        }
        const clientId = localStorage.getItem('client_id');
        const patientId = localStorage.getItem('patient_id');
        if (!(clientId && patientId)) {
          let user_id = arr[arr.length - 1];
          if (user_id === 'easy-pay') {
            user_id = arr[arr.length - 2];
          }

          if (
            user_id.length > 6 &&
            user_id !== 'my-tasks' &&
            user_id.length < 9
          ) {
            let body = {
              user_id,
            };
            this.spinner.show();
            localStorage.setItem('user_id', user_id);
            this.orderService.getBranding(body).subscribe((res) => {
              res.data.primary_color?.length > 0 &&
                localStorage.setItem(
                  'primary_color',
                  res.data.primary_color || '#073786'
                );
              res.data.secondary_color?.length > 0 &&
                localStorage.setItem(
                  'secondary_color',
                  res.data.secondary_color || '#005EFA'
                );
              this.primary_color_light = this.getLightColor(
                res.data.primary_color
              );
              res.data.primary_color;
              const url = res.data.logo_url?.split('?')[0];
              localStorage.setItem('brand_logo', url || 'assets/img/logo.png');
              this.Primary_color = res.data.primary_color || '#073786';
              this.Secondry_color = res.data.secondary_color || '#005EFA';
              this.Primary_color_light = this.primary_color_light || '#4A6186';
              this.spinner.hide();
            }),
              (error) => {
                localStorage.setItem('primary_color', '#073786');
                localStorage.setItem('secondary_color', '#005EFA');
                this.primary_color_light = this.getLightColor('#073786');
                localStorage.setItem('brand_logo', 'assets/img/logo.png');
                console.error(error);
                this.spinner.hide();
              };
          }
        } else if (params.client_id && params.patient_id) {
          let body = {
            patient_id: params.patient_id,
            client_id: params.client_id,
          };
          this.orderService.getBranding(body).subscribe((res) => {
            res.data.primary_color?.length > 0
              ? localStorage.setItem('primary_color', res.data.primary_color)
              : localStorage.setItem('primary_color', '#073786');
            const url = res.data.logo_url?.split('?')[0];
            localStorage.setItem('brand_logo', url || 'assets/img/logo.png');
            res.data.secondary_color?.length > 0
              ? localStorage.setItem(
                  'secondary_color',
                  res.data.secondary_color
                )
              : localStorage.setItem('secondary_color', '#005EFA');
            this.Primary_color = res.data.primary_color || '#073786 ';
            this.Secondry_color = res.data.secondary_color || '#005EFA';
            this.primary_color_light = this.getLightColor(
              res.data.primary_color
            );
            this.Primary_color_light = this.primary_color_light || '#4A6186';
          }),
            (error) => {
              localStorage.setItem('primary_color', '#073786');
              localStorage.setItem('secondary_color', '#005EFA');
              this.primary_color_light = this.getLightColor('#073786');
              localStorage.setItem('brand_logo', 'assets/img/logo.png');
              console.error(error);
              this.spinner.hide();
            };
        }
        let local_user_id = localStorage.getItem('user_id');
        if (local_user_id && local_user_id === 'my-tasks') {
          localStorage.removeItem('user_id');
        }
      });
  }
}
