<div class="screen screen-3">
  <div class="logo">
    <img class="logo-img" src="assets/img/logo.png" alt="logo" />
  </div>
  <p class="text" style="margin-top: 15px">
    Please enter your cell phone number and date of birth below. We will use
    this information to confirm your identity with the information on file at
    your<br />
    provider's office.
  </p>

  <form [formGroup]="form" (ngSubmit)="linkUser()">
    <div class="input-otr">
      <p class="input-text-1">Cell Phone</p>
      <div
        *ngIf="f.cell_phone.touched && f.cell_phone.invalid"
        class="alert alert-danger"
      >
        <div class="error-text" *ngIf="f.cell_phone.errors.required">
          Invalid Cell Phone
        </div>
      </div>
      <input
        class="input"
        type="text"
        name="cell_phone"
        id="cell_phone"
        [(ngModel)]="inputModel.cell_phone"
        formControlName="cell_phone"
      />
    </div>
    <div class="input-otr">
      <p class="input-text-2">Date Of Birth</p>
      <div class="alert alert-danger">
        <div class="error-text" *ngIf="dobFlag">Invalid Date of Birth</div>
      </div>
      <!-- <mat-form-field appearance="none" style="width: 100%;border-bottom: 1px solid #ced4da;" >
                <input  class="date-of-birth" matInput formControlName="date_of_birth" [matDatepicker]="picker2" [min]="minDate" [max]="maxDate" #dateOfBirth   >
                <mat-datepicker-toggle  matSuffix [for]="picker2">
                </mat-datepicker-toggle>
                <mat-datepicker style="border-bottom: 1px solid #ced4da;" #picker2></mat-datepicker>
            </mat-form-field> -->

      <mat-form-field
        appearance="none"
        style="width: 100%; border-bottom: 1px solid #ced4da"
      >
        <input
          class="date-of-birth"
          matInput
          [textMask]="mask"
          [matDatepicker]="picker2"
          [min]="minDate"
          (click)="onClick($event)"
          [max]="maxDate"
          (dateChange)="onDateChange($event)"
          placeholder="Choose a date"
          #dateOfBirth
        />
        <mat-datepicker-toggle matSuffix [for]="picker2">
        </mat-datepicker-toggle>
        <mat-datepicker
          style="border-bottom: 1px solid #ced4da"
          #picker2
        ></mat-datepicker>
      </mat-form-field>

      <br />
    </div>
    <div class="input-otr-password">
      <div class="form-group">
        <label class="input-text-1">Username:</label>
        <div
          *ngIf="f.username.touched || f.username.dirty"
          class="alert alert-danger"
        >
          <p *ngIf="f.username.errors?.minlength" class="error-text">
            <i class="fa fa-times mr-1" aria-hidden="true"></i>Username must be
            atleast 6 characters long.
          </p>
          <p *ngIf="f.username.errors?.maxlength" class="error-text">
            <i class="fa fa-times mr-1" aria-hidden="true"></i>Username can only
            be of 20 characters max.
          </p>
          <p *ngIf="f.username.errors?.required" class="error-text">
            <i class="fa fa-times mr-1" aria-hidden="true"></i>Username is
            required to link.
          </p>
        </div>

        <input type="text" class="input" formControlName="username" />
      </div>
      <div class="form-group" style="margin-top: 4%">
        <label class="input-text-1">Password:</label>
        <div *ngIf="f.password.touched || f.password.dirty">
          <p *ngIf="f.password.errors?.required" class="error-text">
            <i class="fa fa-times mr-1" aria-hidden="true"></i>Password is
            required to link.
          </p>
          <p *ngIf="f.password.errors?.minlength" class="error-text">
            <i class="fa fa-times mr-1" aria-hidden="true"></i>Password must be
            atleast 8 characters long.
          </p>
        </div>
        <input
          class="input"
          [type]="!showPass ? 'password' : 'text'"
          formControlName="password"
        />
        <img
          [src]="
            !showPass ? 'assets/icons/hidden.png' : 'assets/icons/disclosed.png'
          "
          (click)="togglePassword()"
          class="img-inr eye-icon hand-cursor"
          height="25"
          alt="hidden"
        />
      </div>
    </div>
    <div class="enable-otr">
      &nbsp;
      <!-- <input type="checkbox" id="switch" /><label for="switch"><p class="text">Enable Face ID</p></label> -->
    </div>
    <div class="action-otr login-btn" style="padding-top: 22px !important">
      <button
        id="signup-btn"
        [disabled]="form.invalid || dobFlag"
        class="btn-login hand-cursor"
        type="submit"
      >
        Link Account
      </button>
    </div>
  </form>
</div>

<!--==========================
       Screen-2 End Here
===========================-->
