<mat-dialog-content class="mat-typography">
  <button
    class="close"
    mat-button
    mat-dialog-close
    style="float: right; font-weight: 700; cursor: pointer"
  >
    <i aria-label="close dialog" class="fa fa-times"></i>
  </button>
  <div class="info-container">
    <div class="child-container">
      <p>{{ description }}</p>
      <img *ngIf="image_url" width="100%" height="125px" [src]="image_url" />
    </div>
  </div>
  <br />
  <hr style="color: gray" />
  <br />
  <google-map
    width="100%"
    height="225px"
    [zoom]="zoom"
    [center]="center"
    [options]="options"
  >
    <map-marker
      *ngFor="let marker of markers"
      [position]="marker.position"
      [title]="marker.options.title"
    ></map-marker>
  </google-map>
  <br /><br />
</mat-dialog-content>
<div class="contact-btn" *ngIf="directions == true">
  <button class="btn-contact hand-cursor" mat-button (click)="openGoogleMaps()">
    Driving Directions
  </button>
</div>
