import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderService } from '../services/order.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css'],
})
export class OrderListComponent implements AfterViewInit {
  @ViewChild('owlCar', { static: false }) owlCar: any;
  clientResponse: any;
  ordersResponse: any;
  dynamicSlides: any;
  arrayOfUsers: any;
  recordIndex: any;
  slideId: any;
  isPastOrder: boolean = false;
  constructor(
    private orderService: OrderService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.dynamicSlides = {};
    this.arrayOfUsers = [];
    this.getUsers();
    if (localStorage.getItem('token')) {
      if (
        this.orderService.orderCurrentSlide === 0 ||
        this.orderService.orderCurrentSlide === undefined
      ) {
        this.orderService.orderCurrentSlide = 0;
        this.getPendingOrders(this.orderService.orderCurrentSlide);
      }
    }
  }
  ngAfterViewInit() {
    if (this.owlCar) {
      this.owlCar.to(this.orderService.orderCurrentSlide.toString());
    }
  }
  getUsers() {
    this.recordIndex = JSON.parse(localStorage.getItem('multi_user_ids'));
    if (this.recordIndex !== null && this.recordIndex.length > 0) {
      for (var i = 0; i < this.recordIndex.length; i++) {
        this.arrayOfUsers[i] = '';
      }
    }
  }

  getPendingOrders(slideIndex: number) {
    this.spinner.show();
    this.orderService.getPendingOrder(slideIndex).subscribe(
      (response) => {
        this.dynamicSlides = {
          ordersResponse: response.data.data.orders,
          pastOrdersResponse: response.data.data.past_orders,
          isPastOrder:
            response.data.data.past_orders &&
            response.data.data.past_orders.length > 0
              ? true
              : undefined,
          clientResponse: response.data.data.client,
          ordersResponseNew: response.data.data.orders,
        };
        if (this.arrayOfUsers[slideIndex] === '') {
          this.arrayOfUsers[slideIndex] = this.dynamicSlides;
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error.error.message);
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  viewOrders(userObj) {
    if (userObj.isPastOrder) {
      this.arrayOfUsers[this.orderService.orderCurrentSlide].ordersResponse =
        userObj.pastOrdersResponse;
    } else {
      this.arrayOfUsers[this.orderService.orderCurrentSlide].ordersResponse =
        userObj.ordersResponseNew;
    }
    if (userObj.pastOrdersResponse && userObj.pastOrdersResponse.length > 0) {
      userObj.isPastOrder = !userObj.isPastOrder;
    } else {
      userObj.isPastOrder = undefined;
    }
  }

  navigateOrderDetails(orderId) {
    this.router.navigate(['/order-detail/' + orderId]);
  }

  customOptions: OwlOptions = {
    loop: false,
    dots: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    autoHeight: true,
    navSpeed: 600,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      760: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  getData($event) {
    this.spinner.show();
    this.orderService.orderCurrentSlide = $event.startPosition;
    if (this.arrayOfUsers[$event.startPosition] === '') {
      this.getPendingOrders($event.startPosition);
    } else {
      this.spinner.hide();
      return;
    }
  }
  reset(): void {
    this.orderService.orderCurrentSlide = 0;
  }
}
