import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService {
  constructor(private httpClient: HttpClient) {}
  public appiontmentCurrentSlide: number;
  getAllAppointments(pageNum?: number) {
    const recordIndex = JSON.parse(localStorage.getItem('multi_user_ids'));
    const httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('token'),
      patient_id: recordIndex[pageNum].patient_id,
      client_id: recordIndex[pageNum].client_id,
    });

    return this.httpClient.get<any>(`${environment.URL}order/appointments`, {
      headers: httpHeaders,
    });
  }
  confirmAppointment(AppointId, isConfirm) {
    if(isConfirm === true){
    let header = new HttpHeaders({
      Authorization: localStorage.getItem('token'),
    });
    const params = new HttpParams();
    return this.httpClient.put<any>(
      `${environment.URL}order/appointment/${AppointId}/confirm/${params.set(
        'is_confirmed',
        '1'
      )}`,
      { params: params },
      { headers: header }
    );
  }}
}
