import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactOfficeComponent } from './contact-office/contact-office.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { OrderListComponent } from './order-list/order-list.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { TermOfUseComponent } from './term-of-use/term-of-use.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { ProviderListComponent } from './provider-list/provider-list.component';
import { MyPaymentsComponent } from './my-payments/my-payments.component';
import { StripeErrorComponent } from './stripe-error/stripe-error.component';
import { MyTasksComponent } from './my-tasks/my-tasks.component';
import { TaskDetailsComponent } from './task-details/task-details.component';
import { MFAComponent } from './mfa/mfa.component';
import { Page404Component } from './page404/page404.component';
import { ConversationListComponent } from './conversation-list/conversation-list.component';
import { LinkUserComponent } from './link-user/link-user.component';
import { SiteNavigatorComponent } from './site-navigator/site-navigator.component';
import { VerificationComponent } from './verification/verification.component';
import { EzAccessComponetComponent } from './ez-access-componet/ez-access-componet.component';

const appRoutes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'verification/:user_type', component: VerificationComponent },
  { path: 'register/:user_type', component: SignUpComponent },
  { path: 'login', component: LoginComponent },
  { path: 'mfa', component: MFAComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'order-list', component: OrderListComponent },
  { path: 'order-detail/:order_id', component: OrderDetailComponent },
  { path: 'term-of-use', component: TermOfUseComponent },
  {
    path: 'contact-office/:practice_id/:practice_Name',
    component: ContactOfficeComponent,
  },
  { path: 'appointments', component: AppointmentsComponent },
  { path: 'provider-list', component: ProviderListComponent },
  { path: 'conversation-list', component: ConversationListComponent },
  { path: 'my-payments', component: MyPaymentsComponent },
  { path: 'my-tasks', component: MyTasksComponent },
  { path: 'task-detail/:task_id/:patient_id', component: TaskDetailsComponent },
  { path: 'error', component: StripeErrorComponent },
  { path: '', component: SiteNavigatorComponent },
  { path: ':id', component: HomeComponent },
  { path: ':id/easy-pay', component: EzAccessComponetComponent },
  { path: '404', component: Page404Component },
  { path: '**', redirectTo: '/404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
