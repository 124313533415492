import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, throwError } from 'rxjs';;
import { catchError } from "rxjs/operators";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const modifiedReq = request.clone({
            headers: request.headers.set('platform', 'pwa'),
        });
        return next.handle(modifiedReq).pipe(catchError(err => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401 || err.status === 403) {
                    window.location.href = "/login";
                    return new Observable<HttpEvent<any>>();
                }
                if (err.status === 404) {
                    const error = (err && err.error && err.error.code) || err.statusText;
                    console.error(err);
                    return throwError(error);
                }
                if (err.status !== 401 && err.status !== 403 && err.status !== 404) {
                    return throwError(err);
                }
            }
            // if ([401, 403].includes(err.status)) {
            //     // auto logout if 401 or 403 response returned from api
            //     window.location.href = "/login";
            // }
            //  const error = (err && err.error && err.error.message) || err.statusText;
            // console.error(err);
            // return throwError(error);
        }))
    }

}