import { AfterViewInit, Component, OnInit } from '@angular/core';
import { loginModel } from '../models/login';
import { LoginService } from '../services/login.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(600)]),
      transition(':leave', animate(600, style({ opacity: 0 }))),
    ]),
    trigger('fadeIn', [
      transition(':enter', [style({ opacity: 0 }), animate(760)]),
    ]),
  ],
})
export class LoginComponent implements OnInit {
  showPass: boolean = false;
  showConfirmPass: boolean = false;
  inputModel: loginModel;
  signinForm: FormGroup;
  verifyCode: FormGroup;
  resetCredentials: FormGroup;
  cell_phone_end: string;
  loginWithPIN: boolean = false; /* Login using PIN Screen */
  disablePIN: boolean = false;
  loginWithCredentials: boolean =
    false; /* Login with Username/Password Screen */
  verifyMFAScreen: boolean = false; /* Verify MFA Code Screen */
  changePIN: boolean = false;
  changePass: boolean = false;
  changePINPass: boolean = false; /* PIN/Password Reset Screen */
  forgotUsername: boolean = false;
  resetPin: boolean = false;
  apiExecuted = false;
  brandlogo: string;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
    this.inputModel = new loginModel();
    this.inputModel.pin_code = '';
    if (localStorage.getItem('client_id')) {
      this.inputModel.client_id = localStorage.getItem('client_id');
    }
    if (localStorage.getItem('patient_id')) {
      this.inputModel.patient_id = localStorage.getItem('patient_id');
    }
    if (localStorage.getItem('practice_id')) {
      this.inputModel.practice_id = localStorage.getItem('practice_id');
    }
  }

  async ngOnInit(): Promise<void> {
    await this.checkBrandLogo();
    if (!this.inputModel.client_id || !this.inputModel.patient_id) {
      this.disablePIN = true;
      this.switchLoginToCredentials();
    } else {
      this.loginService
        .doesUserExist(this.inputModel.client_id, this.inputModel.patient_id)
        .subscribe(
          (resp) => {
            this.spinner.hide();
            this.apiExecuted = true;
            this.resetPin = true;
            if (!resp?.data?.has_pin_code) {
              this.disablePIN = true;
              this.switchLoginToCredentials();
            }
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(error);
          }
        );
    }

    this.signinForm = this.formBuilder.group({
      username: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(20),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
    });
    // this.resetPin=true;
  }
  delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
  checkBrandLogo = async () => {
    this.spinner.show();
    await this.delay(2000);
    if (localStorage.getItem('brand_logo')) {
      this.brandlogo = localStorage.getItem('brand_logo');
    } else {
      this.brandlogo = 'assets/img/logo.png';
    }
    this.spinner.hide();
  };

  get f(): any {
    return this.signinForm.controls;
  }

  get f2(): any {
    return this.verifyCode.controls;
  }

  get f3(): any {
    return this.resetCredentials.controls;
  }

  numberPress(number) {
    this.inputModel.pin_code = this.inputModel.pin_code + number;
    if ('vibrate' in navigator) {
      // vibration API supported
      navigator.vibrate(150);
    }
    if (this.inputModel.pin_code.length === 4) {
      this.login();
    }
  }

  switchLoginToCredentials() {
    this.loginWithPIN = false;
    this.loginWithCredentials = !this.loginWithCredentials;
  }

  switchLoginToPIN() {
    this.loginWithCredentials = false;
    this.loginWithPIN = !this.loginWithPIN;
  }

  pinDelete() {
    this.inputModel.pin_code = '';
  }

  pinBackspace() {
    this.inputModel.pin_code = this.inputModel.pin_code.slice(0, -1);
  }

  login(credentials: boolean = false) {
    this.spinner.show();

    if (this.inputModel.pin_code.length > 0) {
      this.inputModel.pin_code = this.inputModel.pin_code + '';
    }

    if (credentials) {
      this.inputModel.username = this.f.username.value;
      this.inputModel.password = this.f.password.value;
      this.inputModel.pin_code = '';
      this.inputModel.client_id = '';
      this.inputModel.patient_id = '';
    } else {
      this.inputModel.username = '';
      this.inputModel.password = '';
    }

    this.loginService.login(this.inputModel).subscribe(
      (response) => {
        if (response.data.client_id) {
          localStorage.setItem('client_id', response.data.client_id);
        }
        if (response.data.patient_id) {
          localStorage.setItem('patient_id', response.data.patient_id);
        }
        if (response.data.token) {
          localStorage.setItem('token', response.data.token);
        }
        if (response.data.patient_name) {
          localStorage.setItem('patient_name', response.data.patient_name);
        }
        if (response.data.first_name) {
          localStorage.setItem('first_name', response.data.first_name);
        }
        if (response.data.last_name) {
          localStorage.setItem('last_name', response.data.last_name);
        }
        if (response.data.date_of_birth) {
          localStorage.setItem('date_of_birth', response.data.date_of_birth);
        }
        if (response.data.med_rec_nbr) {
          localStorage.setItem('med_rec_nbr', response.data.med_rec_nbr);
        }
        if (response.data.user_ids) {
          localStorage.setItem(
            'multi_user_ids',
            JSON.stringify(response.data.user_ids)
          );
        }
        localStorage.setItem(
          'credentials_registered',
          response.data.credentials_registered
        );
        this.spinner.hide();
        this.router.navigate(['dashboard']);
      },
      (error) => {
        this.spinner.hide();
        this.toastr.toastrConfig.timeOut = 3000;
        if (error.error) {
          if (error.error.code === 303) {
            if (error.error.data && error.error.data.cell_phone_end) {
              localStorage.setItem(
                'cell_phone_end',
                error.error.data.cell_phone_end
              );
            }
            window.location.href = '/mfa';
          } else {
            if (error.error.code === 400) {
              this.inputModel.pin_code = '';
            }
            this.toastr.error(error.error.message);
          }
        } else {
          this.inputModel.pin_code = '';
          this.toastr.error(error);
        }
      }
    );
  }

  togglePassword() {
    this.showPass = !this.showPass;
  }

  toggleConfirmPassword() {
    this.showConfirmPass = !this.showConfirmPass;
  }

  forgotUserNamePrompt() {
    Swal.fire({
      title: 'Forgot Username',
      icon: 'info',
      html: `
      <style>
        .swal2-input {
          width: 70%;
        }
      </style>
      <input type="text" id="last_name" class="swal2-input" placeholder="Enter Last Name">
      <input type="date" id="date_of_birth" class="swal2-input" placeholder="Date of Birth">
    `,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      customClass: {
        validationMessage: 'swal-validation',
      },
      preConfirm: () => {
        const last_name = (
          document.getElementById('last_name') as HTMLInputElement
        ).value;
        let date_of_birth = (
          document.getElementById('date_of_birth') as HTMLInputElement
        ).value;
        if (!last_name || !date_of_birth) {
          Swal.showValidationMessage('Both fields are required');
        }
        return { last_name, date_of_birth };
      },
      confirmButtonText: 'Proceed',
    }).then((result) => {
      if (result.isConfirmed) {
        this.forgotUsername = true;
        this.processForgotUsername(result.value);
      }
    });
  }

  processForgotUsername(data: any) {
    this.spinner.show();
    this.loginService.sendMFAForPasswordByDetails(data).subscribe(
      (response) => {
        this.spinner.hide();
        this.toastr.error('Failed to send MFA code, please try again later.');
      },
      (error) => {
        this.spinner.hide();
        this.toastr.toastrConfig.timeOut = 3000;
        if (error.error) {
          if (error.error.code === 303) {
            if (error.error.data && error.error.data.cell_phone_end) {
              this.changePass = false;
              if (error.error.data.cell_phone_end) {
                localStorage.setItem(
                  'cell_phone_end',
                  error.error.data.cell_phone_end
                );
                this.cell_phone_end = error.error.data.cell_phone_end;
              }
              if (error.error.data.patient_id) {
                localStorage.setItem('patient_id', error.error.data.patient_id);
                this.inputModel.patient_id = error.error.data.patient_id;
              }
              if (error.error.data.client_id) {
                localStorage.setItem('client_id', error.error.data.client_id);
                this.inputModel.client_id = error.error.data.client_id;
              }
              this.loginWithCredentials = false;
              this.loginWithPIN = false;
              this.verifyCode = this.formBuilder.group({
                MFACode: new FormControl('', [
                  Validators.required,
                  Validators.minLength(6),
                  Validators.pattern('^[0-9]*$'),
                ]),
              });
              this.verifyMFAScreen = true;
            }
          } else {
            if (error.error.code === 400) {
              this.inputModel.pin_code = '';
            }
            this.toastr.error(error.error.message);
          }
        } else {
          this.inputModel.pin_code = '';
          this.toastr.error(error);
        }
      }
    );
  }

  resetPrompt(resetPassword: boolean = false) {
    Swal.fire({
      title: 'Reset PIN/Password',
      text: 'The process to reset your PIN/password will begin, are you sure you want to continue?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      customClass: 'swal-resize',
      confirmButtonText: 'Yes, Confirm it!',
    }).then((result) => {
      if (result.isConfirmed) {
        /* CASE FOR PASSWORD RESET */
        if (resetPassword) {
          Swal.close();
          Swal.fire({
            title: 'Password Reset',
            text: 'To begin the process, please enter the username for your account.',
            icon: 'info',
            input: 'text',
            inputPlaceholder: 'Username',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            customClass: {
              validationMessage: 'swal-validation',
            },
            preConfirm: (value) => {
              if (!value) {
                Swal.showValidationMessage('Username is required');
              }
              if (value.length > 20)
                Swal.showValidationMessage(
                  'Username can only have 20 characters max'
                );
            },
            confirmButtonText: 'Proceed',
          }).then((result) => {
            if (result.isConfirmed) {
              this.spinner.show();
              const data = {
                username: result.value,
              };
              this.loginService.sendMFAForPassword(data).subscribe(
                (response) => {
                  this.spinner.hide();
                  this.toastr.error(
                    'Failed to send MFA code, please try again later.'
                  );
                },
                (error) => {
                  this.spinner.hide();
                  this.toastr.toastrConfig.timeOut = 3000;
                  if (error.error) {
                    if (error.error.code === 303) {
                      if (error.error.data && error.error.data.cell_phone_end) {
                        this.changePass = true;
                        if (error.error.data.cell_phone_end) {
                          localStorage.setItem(
                            'cell_phone_end',
                            error.error.data.cell_phone_end
                          );
                          this.cell_phone_end = error.error.data.cell_phone_end;
                        }
                        if (error.error.data.patient_id) {
                          localStorage.setItem(
                            'patient_id',
                            error.error.data.patient_id
                          );
                          this.inputModel.patient_id =
                            error.error.data.patient_id;
                        }
                        if (error.error.data.client_id) {
                          localStorage.setItem(
                            'client_id',
                            error.error.data.client_id
                          );
                          this.inputModel.client_id =
                            error.error.data.client_id;
                        }
                        this.loginWithCredentials = false;
                        this.loginWithPIN = false;
                        this.verifyCode = this.formBuilder.group({
                          MFACode: new FormControl('', [
                            Validators.required,
                            Validators.minLength(6),
                            Validators.pattern('^[0-9]*$'),
                          ]),
                        });
                        this.verifyMFAScreen = true;
                      }
                    } else {
                      if (error.error.code === 400) {
                        this.inputModel.pin_code = '';
                      }
                      this.toastr.error(error.error.message);
                    }
                  } else {
                    this.inputModel.pin_code = '';
                    this.toastr.error(error);
                  }
                }
              );
            }
          });
        } else {
          /* CASE FOR PIN RESET */
          this.spinner.show();
          this.loginService.sendCode(this.inputModel).subscribe(
            (response) => {
              this.spinner.hide();
              this.toastr.error(
                'Failed to send MFA code, please try again later.'
              );
            },
            (error) => {
              this.spinner.hide();
              this.toastr.toastrConfig.timeOut = 3000;
              if (error.error) {
                if (error.error.code === 303) {
                  if (error.error.data && error.error.data.cell_phone_end) {
                    this.changePIN = true;
                    if (error.error.data.cell_phone_end) {
                      localStorage.setItem(
                        'cell_phone_end',
                        error.error.data.cell_phone_end
                      );
                      this.cell_phone_end = error.error.data.cell_phone_end;
                    }

                    this.loginWithCredentials = false;
                    this.loginWithPIN = false;
                    this.verifyCode = this.formBuilder.group({
                      MFACode: new FormControl('', [
                        Validators.required,
                        Validators.minLength(6),
                        Validators.pattern('^[0-9]*$'),
                      ]),
                    });
                    this.verifyMFAScreen = true;
                  }
                } else {
                  if (error.error.code === 400) {
                    this.inputModel.pin_code = '';
                  }
                  this.toastr.error(error.error.message);
                }
              } else {
                this.inputModel.pin_code = '';
                this.toastr.error(error);
              }
            }
          );
        }
      }
    });
  }

  resendMFACode() {
    this.spinner.show();
    this.loginService.sendCode(this.inputModel).subscribe(
      (response) => {
        this.spinner.hide();
        this.toastr.error(
          'Failed to send security code, please try again later.'
        );
      },
      (error) => {
        this.spinner.hide();
        this.toastr.toastrConfig.timeOut = 3000;
        if (error.error) {
          if (error.error.code === 303) {
            this.toastr.success('Security code sent successfully');
          } else {
            if (error.error.code === 400) {
              this.inputModel.pin_code = '';
            }
            this.toastr.error(error.error.message);
          }
        } else {
          this.inputModel.pin_code = '';
          this.toastr.error(error);
        }
      }
    );
  }

  verifyMFACode() {
    this.spinner.show();
    this.inputModel.mfa_code = this.f2.MFACode.value;
    this.loginService.verifyCode(this.inputModel).subscribe(
      (response) => {
        this.spinner.hide();
        this.verifyMFAScreen = false;

        if (this.changePass) {
          this.proceedToChangeCredentials();
        } else if (this.forgotUsername) {
          Swal.fire({
            title: 'Warning',
            text: 'This action is irreversible. By proceeding, your username and password will be deleted from your account, and you will need to register again to log in.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Proceed',
          }).then((result) => {
            if (result.isConfirmed) {
              const clientId = this.inputModel.client_id;
              const patientId = this.inputModel.patient_id;
              this.loginService.resetPIN(clientId, patientId).subscribe(
                (response) => {
                  this.verifyMFAScreen = false;
                  this.loginWithCredentials = false;
                  const userIds = response.data.userIds;
                  if (userIds && userIds.length > 0) {
                    const userId = userIds[0];
                    localStorage.setItem('user_id', userId);
                  }
                  this.toastr.success('Account removed successfully.');
                  this.router.navigate(['/home']);
                },
                (error) => {
                  this.toastr.success('Account recovery failed.');
                }
              );
            } else {
              this.verifyMFAScreen = true;
            }
          });
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error.error.message);
      }
    );
  }

  proceedToChangeCredentials() {
    this.inputModel.cnfrm_pin_code = '';
    this.inputModel.pin_code = '';
    this.inputModel.password = '';
    this.inputModel.confirm_password = '';

    if (this.changePass) {
      this.resetPin = false;
      this.resetCredentials = this.formBuilder.group(
        {
          password: new FormControl('', [Validators.minLength(8)]),
          confirm_password: new FormControl('', [Validators.minLength(8)]),
        },
        { validators: this.checkPasswords }
      );
    } else if (this.changePIN) {
      this.resetCredentials = this.formBuilder.group(
        {
          pin_code: new FormControl('', [
            Validators.minLength(4),
            Validators.pattern('^[0-9]*$'),
          ]),
          confirm_pin_code: new FormControl('', [
            Validators.minLength(4),
            Validators.pattern('^[0-9]*$'),
          ]),
        },
        { validators: this.checkPINs }
      );
    }
    this.changePINPass = true;
  }

  checkPasswords(form: FormGroup) {
    return form.controls['password'].value ===
      form.controls['confirm_password'].value
      ? null
      : { mismatch: true };
  }

  checkPINs(form: FormGroup) {
    return form.controls['pin_code'].value ===
      form.controls['confirm_pin_code'].value
      ? null
      : { mismatch: true };
  }

  updateCredentials() {
    this.spinner.show();
    this.inputModel.mfa_code = this.f2.MFACode.value;
    this.loginService.resetCredentials(this.inputModel).subscribe(
      (response) => {
        this.spinner.hide();
        this.toastr.success('Your Credentials were updated successfully.');
        this.changePIN = false;
        this.changePass = false;
        this.inputModel.cnfrm_pin_code = '';
        this.inputModel.pin_code = '';
        this.inputModel.password = '';
        this.inputModel.confirm_password = '';
        this.changePINPass = false;
        this.verifyMFAScreen = false;
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error.error.message);
      }
    );
  }

  resetPinApi() {
    Swal.fire({
      title: 'Warning',
      text: 'This action is irreversible. By proceeding, your username and password will be deleted from your account, and you will need to register again to log in.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      customClass: 'swal-resize',
      confirmButtonText: 'Yes, Confirm it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        const clientId = this.inputModel.client_id;
        const patientId = this.inputModel.patient_id;
        this.loginService.doesUserExist(clientId, patientId).subscribe(
          (resp) => {
            this.spinner.hide();
            this.proceedWithPINReset(clientId, patientId);
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error('Error checking user existence.');
            console.error('Error:', error);
          }
        );
      }
    });
  }

  proceedWithPINReset(clientId: string, patientId: string) {
    this.spinner.show();
    this.loginService.resetPIN(clientId, patientId).subscribe(
      (response) => {
        this.spinner.hide();
        this.toastr.success('PIN reset successfully.');
        this.router.navigate(['/home']);
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error('Failed to reset PIN.');
        console.error('Error:', error);
      }
    );
  }
  
}
