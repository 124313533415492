import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-site-navigator',
  templateUrl: './site-navigator.component.html',
  styleUrls: ['./site-navigator.component.css'],
})
export class SiteNavigatorComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    function check_iOS() {
      return (
        [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod',
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      );
    }
    if (
      localStorage.getItem('user_id')?.length > 0 ||
      localStorage.getItem('user_id') !== null
    ) {
      const queryParams = { user_id: localStorage.getItem('user_id') };
      this.router.navigate(['home'], { queryParams: queryParams });
    } else if (localStorage.getItem('user_id') === null) {
      if (check_iOS()) {
        if (localStorage.getItem('primary_color')?.length > 0) {
          this.router.navigate(['home']);
        }
      } else {
        window.location.href = environment.MARKETING_SITE_URL;
      }
    }
  }
}
