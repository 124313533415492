import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil, timeInterval } from 'rxjs/operators';
import { OrderService } from '../services/order.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { FacilityLocationsComponent } from '../facility-locations/facility-locations.component';
import { LocationsService } from '../services/locations.service';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css'],
})
export class OrderDetailComponent implements OnInit {
  private unsubscribeSource: Subject<any>;
  orderDetails: any;
  orderId: any;
  geocoder: google.maps.Geocoder;
  lat: any;
  lng: any;
  time: any;
  formatted_address: any;
  facility_contact: any;
  step_id: String;
  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public locationService: LocationsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.unsubscribeSource = new Subject<any>();
    this.initSubscription();
  }

  openDialog(facility) {
    const dialogRef = this.dialog.open(FacilityLocationsComponent, {
      panelClass: 'my-dialog',
      height: '500px',
      width: '370px',
    });
    // facility = facility.substring(1, facility.length() - 1);
    dialogRef.componentInstance.description = facility.description;
    dialogRef.componentInstance.image_url = facility.image_url;
    dialogRef.componentInstance.id = facility;
    dialogRef.componentInstance.lat = this.lat;
    dialogRef.componentInstance.lng = this.lng;
    dialogRef.componentInstance.formatted_address = this.formatted_address;
    dialogRef.afterClosed().subscribe((result) => {});
  }

  initSubscription() {
    this.subscribeToQueryParams();
  }

  private subscribeToQueryParams() {
    // this.route.queryParams
    //   .pipe(takeUntil(this.unsubscribeSource))
    //   .subscribe((params) => {
    //     if (params.order_id) {
    //       this.orderId = params.order_id;
    //       this.getOrderDetails(params.order_id);
    //     }
    //   });
    this.orderId = this.route.snapshot.params['order_id'];
    this.getOrderDetails(this.orderId);
  }

  displayComments(id) {
    this.step_id = id;
  }
  downloadFile(j) {
    window.open(j, '_blank');
  }
  closeComments() {
    this.step_id = '';
  }

  getOrderDetails(orderId) {
    let details;
    this.orderService.getOrderDetails(orderId).subscribe(
      (response) => {
        details = response.data;
        if (details && details.scheduled_date) {
          let d = details.scheduled_date.toString();
          d = d.substring(0, 19);
          let time = moment.parseZone(d).format('HH:mm');
          if (time === '00:00') this.time = null;
          else {
            this.time = moment(d).format('hh:mm a');
          }
          details.scheduled_date = moment.parseZone(d).format('D MMM, YYYY');
        } else {
          this.time = null;
          details.scheduled_date = null;
        }
        details.steps.forEach((element) => {
          if (
            element.status.description === 'Not Started' ||
            element.status.description === 'In Progress' ||
            element.status.description === 'Pending' ||
            element.status.description === 'Patient Waiting' ||
            element.status.description === 'Read' ||
            element.status.description === 'Accepted' ||
            element.status.description === 'Sent' ||
            element.status.description === 'Scheduled' ||
            element.status.description === 'Study Canceled' ||
            element.status.description === 'Initial Payer Denial' ||
            element.status.description === 'Declined'
          ) {
            element.status.yellow = true;

            element.status.color = '#f2c94c'; //---yellow
          }
          if (
            element.status.description === 'Approved' ||
            element.status.description === 'Complete'
          ) {
            element.status.white = true;
            element.status.checked = true;
            element.status.color = '#27AE60';
          }
          if (
            element.status.description === 'Denied' ||
            element.status.description === 'Patient Cancelled' ||
            element.status.description === 'Going Elsewhere'
          ) {
            element.status.color = '#ff0000';
            element.status.checked = true;
            element.status.red = true;
          }
        });
        this.orderDetails = details;
        this.getFacilityContact(details);
        if (this.orderDetails.order_facility) {
          this.getLocationAddresss(this.orderDetails.order_facility);
        }
      },
      (error) => {
        this.toastr.error(error.error.message);
        console.log(error);
      }
    );
  }

  getFacilityContact(order_details) {
    if (order_details.facility_phone) {
      let phone = String(order_details.facility_phone);
      this.facility_contact =
        '(' +
        phone.substring(0, 3) +
        ')' +
        ' ' +
        phone.substring(3, 6) +
        '-' +
        phone.substring(6, 10);
    }
  }

  getLocationAddresss(order_facility) {
    let self = this;
    self.geocoder = new google.maps.Geocoder();
    this.locationService
      .getAddresses(encodeURIComponent(order_facility))
      .subscribe(
        (response) => {
          if (response && response.data) {
            response.data.forEach((res) => {
              if (res) {
                this.getGeoAddress(
                  { address: res.address_1 },
                  function (results) {
                    self.lat = results[0].geometry.location.lat();
                    self.lng = results[0].geometry.location.lng();
                    self.formatted_address = results[0].formatted_address;
                  }
                );
              }
            });
          }
        },
        (error) => {
          // this.toastr.error(error.error.message);
          console.log(error);
        }
      );
  }

  getGeoAddress(request: google.maps.GeocoderRequest, callback): void {
    this.geocoder.geocode(request, function (results, status) {
      if (status === 'OK') {
        callback(results);
      } else {
        alert('Geocode was not successful for the following reason: ' + status);
      }
    });
  }

  navigateToOrders() {
    // window.location.href = '/order-list';
    this.router.navigate(['/order-list']);
  }

  navigateContactOffice() {
    window.location.href =
      '/contact-office?practice_id=' + this.orderDetails.practice;
  }

  navigatePayment() {
    // window.location.href = '/my-payments';
    this.router.navigate(['/my-payments']);
  }
}
