import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Talk from 'talkjs';
import { Location } from '@angular/common';
import { TalkService } from '../services/talk.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-contact-office',
  templateUrl: './contact-office.component.html',
  styleUrls: ['./contact-office.component.css'],
})
export class ContactOfficeComponent implements OnInit {
  private unsubscribeSource: Subject<any>;
  private inbox: Talk.Inbox;
  private session: Talk.Session;
  practiceId: string;
  practiceName: string;
  @ViewChild('talkjsContainer') talkjsContainer!: ElementRef;
  constructor(
    private talkService: TalkService,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.unsubscribeSource = new Subject<any>();
    this.initSubscription();
  }

  initSubscription() {
    this.subscribeToQueryParams();
  }

  private subscribeToQueryParams() {
    this.practiceId = this.route.snapshot.params['practice_id'];
    this.practiceName = this.route.snapshot.params['practice_Name'];
    this.createInbox();
  }
  goBack(): void {
    this.location.back();
  }

  private async createInbox() {
    const session = await this.talkService.createCurrentSession(
      this.practiceId.toLowerCase()
    );
    this.inbox = await this.talkService.createInbox(
      session,
      this.practiceId.toLowerCase(),
      this.practiceName
    );
    this.inbox.mount(this.talkjsContainer.nativeElement);
  }
}
