import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '../services/order.service';
import { StripeService } from 'ngx-stripe';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-my-payments',
  templateUrl: './my-payments.component.html',
  styleUrls: ['./my-payments.component.css'],
})
export class MyPaymentsComponent implements OnInit {
  @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;
  paymentInfo: any;
  dynamicSlides: any;
  arrayOfUsers: any;
  totalSlides: number;
  paymentStatment: any;
  totalStatements: any;
  arrayOfStatments: any;
  paymentHandler: any = null;
  latestPaymentDate: string;
  blob: any;
  payObj: any;
  recordIndex: any;
  length = 0;
  private unsubscribeSource: Subject<any>;

  constructor(
    private location: Location,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private stripeService: StripeService,
    private toastr: ToastrService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {
    this.paymentInfo = {};
    this.dynamicSlides = {};
    this.arrayOfUsers = [];
    this.totalSlides = 1;
    this.totalStatements = {};
    this.arrayOfStatments = [];
  }

  ngOnInit(): void {
    this.unsubscribeSource = new Subject<any>();
    this.spinner.show();
    this.getData();
    this.initSubscription();
    this.getPayment(0, true);
  }

  async getData() {
    this.recordIndex = JSON.parse(localStorage.getItem('multi_user_ids'));
    if (this.recordIndex !== null && this.recordIndex.length > 0) {
      for (var i = 0; i < this.recordIndex.length; i++) {
        this.arrayOfUsers[i] = '';
        this.arrayOfStatments[i] = '';
      }
    }
  }

  initSubscription() {
    this.subscribeToQueryParams();
  }

  private subscribeToQueryParams() {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribeSource))
      .subscribe(async (params) => {
        let orderId, benefitId;

        if (params.orderId) {
          orderId = params.orderId;
        }
        if (params.benefitId) {
          benefitId = params.benefitId;
        }
        if (params.benefitId && params.orderId) {
          this.markPaymentAsComplete(orderId, benefitId);
        }
        if (params.success) {
          await this.delay(2000);
          this.arrayOfStatments.forEach((statement) => {
            statement?.paymentStatment?.forEach((payment) => {
              if (Number(params.success) === payment.id) {
                payment.is_paid = true;
              }
            });
          });

          this.toastr.success('Payment made succussfully');
        }
        if (params.fail === '') {
          this.toastr.info('Payment was not successful.');
        }
      });
  }

  delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  getPayment(pageNum: number, showSpinner: boolean = false) {
    this.latestPaymentDate = null;
    this.getPaymentStatment(pageNum);
    if (!showSpinner) this.spinner.show();
    this.orderService.getPayment(pageNum).subscribe(
      (response) => {
        this.paymentInfo = response.data;
        this.spinner.hide();
        this.dynamicSlides = {
          paymentInfo: this.paymentInfo.client,
          orders: this.paymentInfo.orders,
        };
        if (this.arrayOfUsers[pageNum] === '') {
          this.arrayOfUsers[pageNum] = this.dynamicSlides;
        }
        this.spinner.hide();

        if (!showSpinner) this.spinner.hide();
      },
      (error) => {
        if (!showSpinner) this.spinner.hide();
        this.toastr.error(error?.error?.message);
        console.log(error);
      }
    );
  }

  checkout(amount, obj: any) {
    this.orderService.orderCurrentSlide = 0;
    let self = this;
    self.payObj = obj;
    // Check the server.js tab to see an example implementation
    if (document.referrer) {
      localStorage.setItem('back', document.referrer);
    }
    this.orderService
      .createCheckout(self.payObj.order_id, self.payObj.benefits_id)
      .pipe(
        switchMap((response) => {
          return this.stripeService.redirectToCheckout({
            sessionId: response.data.sessionId,
          });
        })
      )
      .subscribe(
        (result) => {
          // If `redirectToCheckout` fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using `error.message`.
          if (result.error) {
            alert(result.error.message);
          }
        },
        (error) => {
          this.toastr.error(error.error.message);
          console.log(error);
        }
      );
  }

  markPaymentAsComplete(orderId, benefits_id) {
    this.spinner.show();
    this.orderService.markPaymentAsComplete(orderId, benefits_id).subscribe(
      (response) => {
        this.spinner.hide();
        if (response.code === 200) {
          this.toastr.success('Your payment has been completed successfully');
          history.pushState({}, '', '/my-payments');
          this.getPayment(0, true);
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error.error.message);
        console.log(error);
      }
    );
  }

  getPaymentStatment(pageNum: number) {
    this.orderService.getPaymentStatment(pageNum).subscribe(
      (response) => {
        this.totalStatements = {
          paymentStatment: response.data,
          length: response.data.length,
        };

        if (this.arrayOfStatments[pageNum] === '') {
          this.arrayOfStatments[pageNum] = this.totalStatements;

          this.arrayOfStatments[pageNum].paymentStatment.forEach((stat) => {
            if (
              !this.latestPaymentDate ||
              moment(stat.print_date).isAfter(this.latestPaymentDate)
            )
              this.latestPaymentDate = stat.print_date;
          });
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error?.error?.message);
        console.log(error);
      }
    );
  }

  goBack() {
    let prevLink = document.referrer;
    if (prevLink === '' && localStorage.getItem('back')) {
      prevLink = localStorage.getItem('back');
    }
    let split;
    let lnkExist = prevLink.includes(environment.APP_LINK);
    if (lnkExist) {
      split = prevLink.split(environment.APP_LINK);
    }
    if (split) {
      if (split[1].split('?')[0] === 'order-detail') {
        this.router.navigate(['/order-list']);
      } else this.router.navigate(['/dashboard']);
    } else this.router.navigate(['/dashboard']);
  }

  LoadImageFromService(statementId: any, date: any) {
    this.spinner.show();
    this.orderService.getPDFStatement(statementId).subscribe(
      (data) => {
        this.blob = new Blob([data], { type: 'application/pdf' });

        var downloadURL = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = downloadURL;
        let dt = date.replaceAll('/', '-');
        this.spinner.hide();
        link.download = `statement_${dt}.pdf`;
        link.click();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error.error.message);
        console.log(error);
      }
    );
  }

  createStatementPaymentSession(statementId) {
    this.orderService
      .createStatementCheckoutSession(statementId)
      .pipe(
        switchMap((response) => {
          return this.stripeService.redirectToCheckout({
            sessionId: response.data.sessionId,
          });
        })
      )
      .subscribe(
        (result) => {
          // If `redirectToCheckout` fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using `error.message`.
          if (result.error) {
            alert(result.error.message);
          }
        },
        (error) => {
          this.toastr.error(error.error.message);
          console.log(error);
        }
      );
  }

  customOptions: OwlOptions = {
    loop: false,
    dots: true,
    navSpeed: 600,
    nav: false,
    autoHeight: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      760: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  onDragged($event: any) {
    this.spinner.show();
    if (this.arrayOfUsers[$event.startPosition] === '') {
      this.getPayment($event.startPosition, true);
    } else {
      this.spinner.hide();
      return;
    }
  }

  isPaymentBtnDisabled(date: string) {
    return !moment(date).isSame(moment(this.latestPaymentDate));
  }
}
