import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { env } from 'process';
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private httpClient: HttpClient) {}
  public orderCurrentSlide: number;
  getPendingOrder(pageNum?: number) {
    const recordIndex = JSON.parse(localStorage.getItem('multi_user_ids'));
    const httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('token'),
      patient_id: recordIndex[pageNum].patient_id,
      client_id: recordIndex[pageNum].client_id,
    });
    return this.httpClient.get<any>(`${environment.URL}order/pending`, {
      headers: httpHeaders,
    });
  }
  getPayment(pageNum?: number) {
    const recordIndex = JSON.parse(localStorage.getItem('multi_user_ids'));
    const httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('token'),
      patient_id: recordIndex[pageNum].patient_id,
      client_id: recordIndex[pageNum].client_id,
    });

    return this.httpClient.get<any>(`${environment.URL}payment`, {
      headers: httpHeaders,
    });
  }
  getOrderDetails(orderId) {
    let header = new HttpHeaders().set(
      'Authorization',
      localStorage.getItem('token')
    );

    return this.httpClient.get<any>(`${environment.URL}order/` + orderId, {
      headers: header,
    });
  }

  markPaymentAsComplete(orderId, benefitsId) {
    let header = new HttpHeaders().set(
      'Authorization',
      localStorage.getItem('token')
    );

    return this.httpClient.post<any>(
      `${environment.URL}order/` +
        orderId +
        '/benefits/' +
        benefitsId +
        '/Complete',
      { is_paid: true },
      { headers: header }
    );
  }

  getPaymentStatment(pageNum?: number) {
    // let header = new HttpHeaders().set(
    //   'Authorization',
    //   localStorage.getItem('token')
    // );
    const recordIndex = JSON.parse(localStorage.getItem('multi_user_ids'));
    const httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('token'),
      patient_id: recordIndex[pageNum].patient_id,
      client_id: recordIndex[pageNum].client_id,
    });
    return this.httpClient.get<any>(
      `${environment.URL}statement/payment-statements`,
      { headers: httpHeaders }
    );
  }

  createCheckout(orderId, benefitsId) {
    let header = new HttpHeaders().set(
      'Authorization',
      localStorage.getItem('token')
    );

    return this.httpClient.post<any>(
      `${environment.URL}order/` +
        orderId +
        '/benefits/' +
        benefitsId +
        '/checkout-session',
      {
        success_url:
          environment.APP_LINK +
          '/my-payments?success&orderId=' +
          orderId +
          '&benefitId=' +
          benefitsId,
        cancel_url: environment.APP_LINK + 'my-payments',
      },
      { headers: header }
    );
  }

  createCheckoutEasyPay(orderId, benefitsId, user_id) {
    let header = new HttpHeaders().set('is_easy_pay', 'true');

    return this.httpClient.post<any>(
      `${environment.URL}order/` +
        orderId +
        '/benefits/' +
        benefitsId +
        '/checkout-session',
      {
        success_url: `${environment.APP_LINK}/${user_id}/easy-pay`,
        cancel_url: `${environment.APP_LINK}/${user_id}/easy-pay`,
      },
      { headers: header }
    );
  }

  getPDFStatement(statementId): Observable<Blob> {
    let header = new HttpHeaders().set(
      'Authorization',
      localStorage.getItem('token')
    );
    return this.httpClient.get(`${environment.URL}statement/` + statementId, {
      headers: header,
      responseType: 'blob',
    });
  }

  createStatementCheckoutSession(paymentId) {
    let header = new HttpHeaders().set(
      'Authorization',
      localStorage.getItem('token')
    );

    return this.httpClient.post<any>(
      `${environment.URL}statement/payment/` + paymentId + '/checkout-session',
      {
        success_url: environment.APP_LINK + `my-payments?success=${paymentId}`,
        cancel_url: environment.APP_LINK + 'my-payments?fail',
      },
      { headers: header }
    );
  }

  createStatementCheckoutSessionEasyPay(
    paymentId,
    user_id,
    patient_id,
    client_id
  ) {
    let header = new HttpHeaders()
      .set('is_easy_pay', 'true')
      .set('patient_id', patient_id)
      .set('client_id', client_id);

    return this.httpClient.post<any>(
      `${environment.URL}statement/payment/` + paymentId + '/checkout-session',
      {
        success_url: `${environment.APP_LINK}/${user_id}/easy-pay`,
        cancel_url: `${environment.APP_LINK}/${user_id}/easy-pay`,
      },
      { headers: header }
    );
  }

  getBranding(body: any) {
    return this.httpClient.post<any>(
      `${environment.URL}practice/branding`,
      body
    );
  }
}
