import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeviceType, TermAndConditionModel } from '../models/termAndCondition';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TermOfUseService } from '../services/term-of-use.service';
import { ToastrService } from 'ngx-toastr';
import emailMask from 'text-mask-addons/dist/emailMask';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-term-of-use',
  templateUrl: './term-of-use.component.html',
  styleUrls: ['./term-of-use.component.css'],
})
export class TermOfUseComponent implements OnInit {
  termInputModel: TermAndConditionModel;
  deviceInfo: any;
  mask = emailMask;
  // checkoutForm = this.formBuilder.group({
  //  email_address:''
  // });
  // private spinnerService: NgxSpinnerService,
  // private toastr: ToastrService,
  constructor(
    private http: HttpClient,
    private deviceService: DeviceDetectorService,
    private termService: TermOfUseService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.termInputModel = new TermAndConditionModel();
  }

  ngOnInit(): void {
    this.getDeviceInfo();
    this.getIPAddress();
  }

  getIPAddress() {
    this.http
      .get('https://api.ipify.org/?format=json')
      .subscribe((res: any) => {
        this.termInputModel.ip_address = res.ip;
      });
  }

  getDeviceInfo() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (this.deviceInfo) {
      this.termInputModel.device = this.deviceService.isTablet()
        ? DeviceType.Tablet
        : this.deviceService.isMobile(this.deviceInfo.userAgent)
        ? DeviceType.Mobile
        : this.deviceService.isDesktop(this.deviceInfo.userAgent)
        ? DeviceType.Desktop
        : DeviceType.Unknown;

      this.termInputModel.browser =
        this.deviceInfo.browser + ' ' + this.deviceInfo.browser_version;
    }
  }

  // validateEmail() {
  //   if (this.termInputModel.email_address !== null && this.termInputModel.email_address !== undefined && this.termInputModel.email_address !== "") {
  //     if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.termInputModel.email_address)) {
  //       return true
  //     }
  //     else return false
  //   } else
  //     return true
  // }

  onSubmit() {
    // var valid = this.validateEmail();
    // if (!valid) {
    //   return this.toastr.error("Invalid Email Address")
    // }
    if (localStorage.getItem('client_id')) {
      this.termInputModel.client_id = localStorage.getItem('client_id');
    }
    if (localStorage.getItem('patient_id')) {
      this.termInputModel.patient_id = localStorage.getItem('patient_id');
    }
    if (localStorage.getItem('practice_id')) {
      this.termInputModel.practice_id = localStorage.getItem('practice_id');
    }
    let userId;
    if (localStorage.getItem('user_id')) {
      userId = localStorage.getItem('user_id');
    }
    // this.spinnerService.show();
    if (
      !this.termInputModel.client_id &&
      !this.termInputModel.patient_id &&
      !userId
    ) {
      return this.toastr.error('Encountered an error, please try again later.');
    }

    // Process checkout data here
    this.termService
      .acceptedTermAndConditions(this.termInputModel, userId)
      .subscribe(
        (response) => {
          localStorage.setItem('client_id', response.data.client_id);
          if (response.data.client_id) {
            this.termInputModel.client_id = response.data.client_id;
          }
          localStorage.setItem('patient_id', response.data.patient_id);
          if (response.data.patient_id) {
            this.termInputModel.patient_id = response.data.patient_id;
          }
          if (response.data.client) {
            localStorage.setItem(
              'client_name',
              response.data.client.description
            );
          }
          //--check if user already exist
          this.termService
            .userAlreadyExist(
              this.termInputModel.patient_id,
              this.termInputModel.client_id
            )
            .subscribe(
              (rsp) => {
                //--existing flow
                if (rsp.data && rsp.data.has_account) {
                  this.router.navigate(['/login']);
                } else if (
                  rsp.data &&
                  !rsp.data.has_account &&
                  rsp.data.has_account_with_other_client
                ) {
                  this.router.navigate(['/verification', 'linkuser']);
                } else if (
                  rsp.data &&
                  !rsp.data.has_account &&
                  !rsp.data.has_account_with_other_client
                ) {
                  this.router.navigate(['/verification', 'signup']);
                }
                this.spinner.hide();
              },
              (error) => {
                console.log(error);
              }
            );
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
