export class TermAndConditionModel {
    patient_id: string;
    client_id: string;
    practice_id: string;
    is_accepted: boolean = true;
    ip_address: string;
    browser: string;
    device: string;
    email_address: string;
}

export enum DeviceType {
    Mobile = 'mobile',
    Tablet = 'tablet',
    Desktop = 'desktop',
    Unknown = 'unknown',
}