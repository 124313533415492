<div class="screen screen-6">
  <div class="order-otr">
    <a style="cursor: pointer" (click)="back()">
      <svg
        class="icon"
        width="24"
        height="24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.47 17.78a.75.75 0 101.06-1.06l-3.97-3.97h8.69a.75.75 0 000-1.5H8.56l3.97-3.97a.75.75 0 00-1.06-1.06l-5.25 5.25c-.002 0-.003.003-.005.005a.747.747 0 00-.215.523v.004a.748.748 0 00.22.529m5.25 5.25l-5.25-5.25z"
          fill="#252F5A"
        />
      </svg>
    </a>
    <p class="heading">Providers</p>
  </div>

  <div class="order-main">
    <div
      class="center-stage"
      style="max-width: 300px; margin: 0 auto; height: 70%"
    >
      <div
        style="
          overflow-x: hidden;
          border-style: solid;
          background-color: #fff;
          border-radius: 5px;
          border-width: 1px;
          border-color: #d0d8dc;
          padding: 10px;
          box-shadow: none;
          margin-top: 25px;
        "
      >
        <ul class="order-list-otr" style="margin: 0 !important">
          <li class="order-list-inr" *ngFor="let list of providerList">
            <a
              class="order-list-single hand-cursor"
              (click)="navigateChatBox(list.practice_id, list.practice_name)"
            >
              <div class="order">
                <span class="circle"></span>
                <p class="desc">{{ list.practice_name }}</p>
              </div>
              <span class="icon">
                <svg
                  class="arrow-icon"
                  width="16"
                  height="16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.667 4l-.94.94L8.78 8l-3.053 3.06.94.94 4-4-4-4z"
                    fill="#252F5A"
                  />
                </svg>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <p class="power-footer">Powered By VYTLsft</p>
  </div>
</div>
